import * as React from 'react';
import * as intl from 'react-intl-universal';
import { alert, confirm, loading } from '@bndynet/dialog';
import {
    DataTable,
    PageHeader,
    DataTableRequestParameters,
    DataTablePageMeta,
} from 'app/ui';
import { createStyles, Theme, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';

const styles = (theme: Theme) => createStyles({});

class History extends React.Component<
    {
        histories?: [];
    },
    {
        histories?: [];
    }
> {
    private objectData = [
        {
            fromCoin: ' XRP',
            fromQuantity: 40000,
            fromValue: 0.00002001,
            timeStamp: '2022-01-01T15:39:36.440Z',
            type: 1,
            description: 'Alış',
        },
    ];

    public constructor(props) {
        super(props);
        this.state = {
            histories: [],
        };
    }

    public render() {
        const histories = this.props.histories;
        if (histories.length > 0) {
            this.objectData = histories;
        }
        return (
            <div>
                <PageHeader title="History" />
                {histories ? (
                    <DataTable
                        data={this.objectData}
                        onRowClick={this.handleRowClick}
                        onRowsDelete={this.handleRowsDelete}
                        selectable="single"
                    />
                ) : (
                    <div>Loading</div>
                )}
                <br />
            </div>
        );
    }

    private handleRowClick(rowData: any) {
        alert(JSON.stringify(rowData));
    }

    private handleRowsDelete() {
        return confirm(intl.get('deleteConfirmMessage')).then(() => {
            // TODO: here to call api
        });
    }
}

const mapStateToProps = state => {
    console.log('history', state);
    return state;
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(History));
