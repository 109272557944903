import * as React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import {
    Paper,
    Theme,
    createStyles,
    withStyles,
    Typography,
    DialogTitle,
    MenuItem,
    Grid,
    FormControl,
    InputLabel,
    Input,
} from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { variantColor } from '../theme';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import InputAdornment from '@material-ui/core/InputAdornment';
import Select from '@material-ui/core/Select';
import apiService from '../service/api.service';
import { actions as historyAction } from '../service/history';
import { Dispatch, Action } from 'redux';
import { connect } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';
import { actions as coinAction } from 'app/service/coins';
import currencyService from 'app/service/api.service';

const miniCardStyles = (theme: Theme) =>
    createStyles({
        ...variantColor(theme),
        root: {
            color:
                theme.palette.type === 'light'
                    ? ''
                    : theme.palette.text.primary,
            '&:hover $icon': {
                transform: 'scale(4)',
            },
        },
        body: {
            padding: `${theme.spacing()}px ${theme.spacing(
                1.5,
            )}px ${theme.spacing(2)}px ${theme.spacing(1.5)}px`,
        },
        icon: {
            top: theme.spacing(),
            right: theme.spacing(),
            opacity: 0.3,
            width: 90,
        },
        iconv2:{
            position: 'absolute',
            top: theme.spacing(),
            right: theme.spacing(),
        },
        select: {
            width: 120,
        },
        linkContainer: {
            display: 'flex',
            backgroundColor: fade(theme.palette.common.black, 0.1),
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4,
            overflow: 'hidden',
            '& > *': {
                flex: 1,
                display: 'block',
                textAlign: 'center',
                paddingTop: theme.spacing() / 4,
                paddingBottom: theme.spacing() / 4,
                opacity: 0.8,
                '&:hover': {
                    backgroundColor: fade(theme.palette.common.black, 0.2),
                    opacity: 1,
                },
            },
        },
    });

class MiniCard extends React.Component<
    {
        coin: any;
        coins: any[];
        unit: number;
        classes: any;
        className?: string;
        title: string;
        description: string;
        icon: any;
        variant?: string;
        key: any;
        links: { [key: string]: string };
        mainCurrency: string;
        fromQuantity: number;
        onLoad: () => void;
        type: string;
    },
    {
        mainCurrency: string;
        unit: number;
        coins: any[];
        exchangeCurrency: any;
        open: boolean;
        type: string;
        fromQuantity: number;
    }
> {
    public selectedCoin: any;
    public quantityCoin: number;
    public newQuantityCoin: number;
    public mainCoin: any;
    public buttonStatus: boolean;

    public constructor(props) {
        super(props);
        this.getCurrency(props);
        this.state = {
            open: false,
            type: '',
            coins: [],
            fromQuantity: 0,
            mainCurrency: props.coin.name,
            unit: 0,
            exchangeCurrency: 'ETH',
        };
        this.buttonStatus = true;
    }

    public getCurrency(props) {
        currencyService
            .getAll()
            .then((response: any) => {
                const tmp = response.data.filter(
                    item => item.value !== props.coin.name,
                );
                this.setState({
                    coins: response.data,
                });
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }

    public handleClickOpenBuy = () => {
        this.setState({ open: true, type: 'buy' });
    };
    public handleClickOpenSell = () => {
        if (this.props.coin.lastPrice <= 0) {
            toast('Insufficient balance, Please try again');
        } else {
            this.setState({ open: true, type: 'sell' });
        }
    };

    public handleClose = () => {
        this.setState({ open: false });
    };

    public handleChangeQuantity = event => {
        this.setState({
            unit: event.target.value,
        });
        this.quantityCoin = event.target.value;
        this.calculate();
    };

    public handleChange = event => {
        this.setState({
            exchangeCurrency: event.target.value,
            fromQuantity:
                Number(event.target.value.value) * Number(this.state.unit),
        });
        this.selectedCoin = event.target.value;
        this.calculate();
    };
    public calculate() {
        let btcValue = this.quantityCoin * Number(this.props.coin.value);
        this.newQuantityCoin = btcValue / this.selectedCoin.value;
        let checkBalance;
        if (this.state.type == 'buy') {
            checkBalance = this.props.coins.find(item => {
                return item.name === this.selectedCoin.name;
            });
        } else {
            checkBalance = this.props.coins.find(item => {
                return item.name === this.props.coin.name;
            });
        }
        console.log(checkBalance);
        if (
            checkBalance.lastPrice < this.newQuantityCoin &&
            this.state.type == 'buy'
        ) {
            toast('Check balance');
            this.buttonStatus = false;
        } else if (
            checkBalance.lastPrice < Number(this.quantityCoin) &&
            this.state.type == 'sell'
        ) {
            toast('Check balance');
            this.buttonStatus = false;
        } else {
            this.buttonStatus = true;
        }
    }

    public handleSubmit = () => {
        let exchangeDtoSell = {
            fromCoin: this.state.mainCurrency,
            fromQuantity: this.quantityCoin,
            fromValue: this.mainCoin.value,
            type: this.state.type == 'sell' ? -1 : 1,
        };
        let exchangeDtoBuy = {
            fromCoin: this.state.exchangeCurrency.name,
            fromQuantity: this.newQuantityCoin,
            fromValue: this.selectedCoin.value,
            type: this.state.type == 'sell' ? 1 : -1,
        };

        Promise.all([
            apiService.exchangeSave(exchangeDtoSell),
            apiService.exchangeSave(exchangeDtoBuy),
        ]).then(res => {
            this.props.onLoad();
            toast('Succesfull');
        });
        this.setState({ open: false });
    };

    public render() {
        const { classes, className, links, coin } = this.props;
        const linkEls = [];
        if (links) {
            for (const key of Object.keys(links)) {
                linkEls.push(this.getLink(key, links[key]));
            }
        }
        this.mainCoin = coin;
        return (
            <Paper
                className={classNames(
                    classes.root,
                    classes[this.props.coin.color],
                    className,
                )}
            >
                <div className={classes.body}>
                    <Typography color="inherit" variant="h4" component="h4">
                        {coin.name}
                    </Typography>
                    <Typography color="inherit" variant="caption">
                        Quantity:{coin.lastPrice}
                        <br />
                        Unit Quantity:{coin.value}
                    </Typography>
                </div>
                <div className={classes.iconv2}>
                    <img src={coin.icon} height="40" />
                </div>
                <Typography
                    className={classes.linkContainer}
                    color="inherit"
                    variant="caption"
                    component="div"
                >
                    <Typography color="inherit" variant="caption">
                        <Typography onClick={this.handleClickOpenBuy}>
                            <a target="_blank" rel="noopener noreferrer">
                                Buy
                            </a>
                        </Typography>
                    </Typography>
                    <Typography color="inherit" variant="caption">
                        <Typography onClick={this.handleClickOpenSell}>
                            <a target="_blank" rel="noopener noreferrer">
                                Sell
                            </a>
                        </Typography>
                    </Typography>
                </Typography>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">
                        {coin.name} - {this.state.type}
                    </DialogTitle>
                    <DialogContent>
                        <form className={classes.form}>
                            <FormControl
                                margin="normal"
                                required={true}
                                fullWidth={true}
                            >
                                <InputLabel htmlFor="coin">Coin</InputLabel>
                                <Input
                                    id="coin"
                                    name="coin"
                                    type="text"
                                    value={this.quantityCoin}
                                    onChange={this.handleChangeQuantity}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {coin.name}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                            <FormControl
                                margin="normal"
                                required={true}
                                fullWidth={true}
                            >
                                <InputLabel htmlFor="currency">from</InputLabel>
                                <Select
                                    autoWidth
                                    id="currency"
                                    value={this.selectedCoin}
                                    onChange={this.handleChange}
                                    inputProps={{
                                        name: 'exchange',
                                        id: 'exchange',
                                    }}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {this.state.coins &&
                                        this.state.coins.map(coin => (
                                            <MenuItem
                                                value={coin}
                                                key={coin.id}
                                            >
                                                {coin.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                            <FormControl
                                margin="normal"
                                required={true}
                                fullWidth={true}
                            >
                                <Input
                                    id="balance"
                                    name="balance"
                                    type="text"
                                    value={this.newQuantityCoin}
                                    disabled={true}
                                />
                            </FormControl>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Cancel
                        </Button>
                        {this.buttonStatus ?
                        (<Button onClick={this.handleSubmit} color="primary">
                            {this.state.type}
                        </Button>) : (<div></div>)
                        }
                    </DialogActions>
                </Dialog>
            </Paper>
        );
    }

    private getLink(text: string, url?: string): JSX.Element {
        if (url) {
            if (url.indexOf('://') > 0) {
                return (
                    <Typography color="inherit" variant="caption" key={text}>
                        <a href={url} target="_blank" rel="noopener noreferrer">
                            {' '}
                            {text}{' '}
                        </a>
                    </Typography>
                );
            } else {
                return (
                    <Link color="default" key={text} to={url}>
                        <Typography color="inherit" variant="caption">
                            {text}
                        </Typography>
                    </Link>
                );
            }
        } else {
            return (
                <Typography color="inherit" variant="caption" key={text}>
                    {text}
                </Typography>
            );
        }
    }
}

const mapStateToProps = state => {
    console.log(state);
    return state;
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    onLoad: (): any => {
        dispatch(historyAction.histories());
        dispatch(coinAction.coins());
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(miniCardStyles)(MiniCard));
