import { Dashboard } from 'app/pages/admin';
import { NotFound } from 'app/pages/public';
import { getState } from 'app/service/coins';

const routes = [
    {
        path: '/admin',
        exact: true,
        component: Dashboard,
    },
    {
        path: '/admin/dashboard',
        exact: true,
        component: Dashboard,
    },
    {
        component: NotFound,
    },
];

export default routes;
