import * as React from 'react';
import BuildIcon from '@material-ui/icons/Build';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DnsIcon from '@material-ui/icons/Dns';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import LayersIcon from '@material-ui/icons/Layers';
import { MenuItem } from 'app/types';

const menus: MenuItem[] = [
    {
        icon: 'fa fa-home',
        text: 'Home',
        description: 'Go to public home',
        link: '',
    },
    {
        icon: 'fa fa-tachometer',
        text: 'Dashboard',
        description: '',
        link: '/admin/dashboard',
    },
];

export default menus;
