import { Config } from './types';

export const config: Config = {
    title: 'admin.brand',
    logoUri: 'http://zcntech.com/wp-content/uploads/2020/05/cropped-LOGO.jpg',
    resourceBaseUri: APP_BASEHREF || '/',
    defaultLocale: 'tr-TR', // empty to use navigator language
    locales: [
        {
            name: 'English',
            value: 'en-US',
            messages: require('../../assets/locales/json/en-US.json'),
        },
        {
            name: 'Turkish',
            value: 'tr-TR',
            messages: require('../../assets/locales/json/tr-TR.json'),
        },
    ],
};
