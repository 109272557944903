import * as React from 'react';

import Typography from '@material-ui/core/Typography';
import { Theme, createStyles, withStyles, Grid } from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import { PageHeader, MiniCard, Tag } from 'app/ui';
import { History } from '../history';
import { Dispatch, Action } from 'redux';
import { connect } from 'react-redux';
import { actions as coinAction } from 'app/service/coins';
import { actions as historyAction } from 'app/service/history';

const styles = (theme: Theme) =>
    createStyles({
        contentHeader: {
            display: 'flex',
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            marginBottom: theme.spacing(),
            '& h2': {
                flex: 1,
            },
        },
        breadcrumb: {
            display: 'flex',
            '& > *': {
                alignSelf: 'flex-end',
                textDecoration: 'none',
            },
            '& > *:not(:last-child):after': {
                content: '">"',
                display: 'inline-block',
                marginLeft: 5,
                marginRight: 5,
            },
        },
        chartContainer: {},
    });

const renderCard = props => {
    return (
        <Grid item={true} md={3} xs={6}>
            <MiniCard
                key={props.coin.id}
                coin={props.coin}
                {...props}
                links={{ Home: '/', 'More info': '/admin/dashboard' }}
                icon={<ShoppingCartIcon />}
            />
        </Grid>
    );
};

class Dashboard extends React.Component<
    {
        classes: any;
        onLoad: () => void;
        coins?: [];
    },
    { coins?: [] }
> {
    private coinTmp = [];
    public constructor(props) {
        super(props);
        this.props.onLoad();
    }

    public render() {
        const coins = this.props.coins;
        if (coins && coins.length > 0) {
            this.coinTmp = coins;
        }
        const { classes } = this.props;
        return (
            <div data-name="top">
                <PageHeader
                    title="Dashboard"
                    navigation={{
                        Home: '/',
                        Dashboard: null,
                    }}
                />
                <Grid container={true} spacing={2}>
                    {this.coinTmp &&
                        this.coinTmp.map(coin =>
                            renderCard({
                                variant: 'success',
                                coin: coin,
                            }),
                        )}
                </Grid>

                <Grid container={true} spacing={2}>
                    <History />
                </Grid>
                <Typography
                    component="div"
                    className={classes.chartContainer}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    console.log(state);
    return state;
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    onLoad: (): any => {
        dispatch(historyAction.histories());
        dispatch(coinAction.coins());
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(Dashboard));
