/* eslint-disable */
import { AxiosPromise } from 'axios';
import { Ajax, AjaxError } from '../helpers/ajax';
import { store } from '../redux';
import { getConfig, AuthType } from '../config';
import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';
import { actions as globalActions } from './global';
import apiService from './api.service';
import currencyService from 'app/service/api.service';

export const ACTION_LIST_EXCHANGE_REQUEST = 'USER_LIST_EXCHANGE_REQUEST';

export interface ExchangeState {
    form?: any;
    histories?: any[];
    coins?: any[];
    error?: any;
}

export const actions = {
    histories: () => ({
        type: ACTION_LIST_EXCHANGE_REQUEST,
        payload: [],
    }),
};

export function reducer(state: ExchangeState = {}, action): ExchangeState {
    switch (action.type) {
        case ACTION_LIST_EXCHANGE_REQUEST: {
            return { ...state };
        }
        default:
            return state;
    }
}

const config = getConfig();
export const service = {
    getHistory: () => {
        return currencyService.getHistory().then(res => {
            return res.data;
        });
    },
};

function* getHistory(action) {
    try {
        yield put(globalActions.showLoading('Loading...'));

        const response = yield call(service.getHistory, { ...action.payload });
        console.log('getHistory');
        response.forEach(element => {
            element.description = element.type == 1 ? 'Alış' : 'Satış';
        });
        getState().histories = response;
        yield put(globalActions.hideLoading());
    } catch (err) {
        yield put(globalActions.hideLoading());
        if (err) {
            const ajaxError = err as AjaxError;
            yield put(
                globalActions.notifyError(
                    `${ajaxError.status}: ${ajaxError.data.error_description}`,
                ),
            );
        } else {
            yield put(globalActions.notifyError(`Service Unavailable`));
        }
    }
}

export function* saga() {
    yield takeLatest(ACTION_LIST_EXCHANGE_REQUEST, getHistory);
}

export function getState(): any {
    return store.getState() as any;
}
