/* eslint-disable */
import { AxiosPromise } from 'axios';
import { Ajax, AjaxError } from '../helpers/ajax';
import { store } from '../redux';
import { getConfig, AuthType } from '../config';
import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';
import { actions as globalActions } from './global';
import currencyService from 'app/service/api.service';

export const KEY_TOKEN = 'token';
export const ACTION_COINS_EXCHANGE_REQUEST = 'USER_COINS_EXCHANGE_REQUEST';
export const ACTION_CREATE_EXCHANGE_REQUEST = 'USER_CREATE_EXCHANGE_REQUEST';

export interface ExchangeState {
    form?: any;
    coins?: any[];
    error?: any;
}

export const actions = {
    coins: () => ({
        type: ACTION_COINS_EXCHANGE_REQUEST,
    }),
    create: (form: any) => ({
        type: ACTION_CREATE_EXCHANGE_REQUEST,
        payload: form,
    }),
};

export function reducer(state: ExchangeState = {}, action): ExchangeState {
    switch (action.type) {
        case ACTION_COINS_EXCHANGE_REQUEST: {
            return { ...state };
        }
        default:
            return state;
    }
}

const config = getConfig();
export const service = {
    coins: () => {
        return Promise.all([
            currencyService.getAll(),
            currencyService.getAllCalculate(),
        ]).then(res => {
            res[0].data.forEach(item => {
                item.lastPrice = 0;
                res[1].data.forEach(calc => {
                    if (calc.coin === item.name) {
                        item.lastPrice += calc.totalValue * calc.type;
                    }
                });
            });
            return res[0].data;
        });
    },
    create: (form: any) => {
        return currencyService.exchangeSave(form).then(res => {
            return res;
        });
    },
};

function* getCoins(action) {
    try {
        yield put(globalActions.showLoading('Loading...'));

        const response = yield call(service.coins, { ...action.payload });
        getState().coins = response;

        yield put(globalActions.hideLoading());
    } catch (err) {
        yield put(globalActions.hideLoading());
        if (err) {
            const ajaxError = err as AjaxError;
            yield put(
                globalActions.notifyError(
                    `${ajaxError.status}: ${ajaxError.data.error_description}`,
                ),
            );
        } else {
            yield put(globalActions.notifyError(`Service Unavailable`));
        }
    }
}

function* createCoin(action) {
    try {
        yield put(globalActions.showLoading('Saving...'));
        console.log(action);
        const response = yield call(service.create, { ...action.payload });
        getState().coins = response;

        yield put(globalActions.hideLoading());
    } catch (err) {
        yield put(globalActions.hideLoading());
        if (err) {
            const ajaxError = err as AjaxError;
            yield put(
                globalActions.notifyError(
                    `${ajaxError.status}: ${ajaxError.data.error_description}`,
                ),
            );
        } else {
            yield put(globalActions.notifyError(`Service Unavailable`));
        }
    }
}

export function* saga() {
    yield takeLatest(ACTION_COINS_EXCHANGE_REQUEST, getCoins);
    yield takeLatest(ACTION_CREATE_EXCHANGE_REQUEST, createCoin);
}

export function getState(): any {
    return store.getState() as any[];
}
