import * as React from 'react';
import * as intl from 'react-intl-universal';
import { Dispatch, Action } from 'redux';

import { withStyles, createStyles, Theme } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import AccountCircleRounded from '@material-ui/icons/AccountCircleRounded';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { actions as authActions } from 'app/service/auth';
import { actions as coinActions } from 'app/service/coins';
import { actions as globalActions } from 'app/service/global';
import apiService from 'app/service/api.service';
import toast from 'react-hot-toast';
import { connect } from 'react-redux';

const styles = (theme: Theme) =>
    createStyles({
        main: {
            width: 'auto',
            display: 'block',
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
            [theme.breakpoints.up(400 + theme.spacing(6))]: {
                width: 400,
                marginLeft: 'auto',
                marginRight: 'auto',
            },
        },
        paper: {
            marginTop: theme.spacing(8),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: `${theme.spacing(2)}px ${theme.spacing(
                3,
            )}px ${theme.spacing(3)}px`,
        },
        avatar: {
            margin: theme.spacing(),
            backgroundColor: theme.palette.secondary.main,
            width: 100,
            height: 100,
            fontSize: 110,
        },
        form: {
            width: '100%',
            marginTop: theme.spacing(),
        },
        submit: {
            marginTop: theme.spacing(3),
        },
    });

interface RegisterComponentProps {
    history: any;
    classes: any;
    onRegister: (
        email: string,
        name: string,
        surname: string,
        password: string,
        passwordConfirm: string,
        rememberMe: boolean,
        open: boolean,
    ) => boolean;
}

interface RegisterComponentState {
    open: boolean;
    email: string;
    passwordConfirm: string;
    password: string;
    name: string;
    surname: string;
    rememberMe: boolean;
}

class Register extends React.Component<
    RegisterComponentProps,
    RegisterComponentState
> {
    public constructor(props) {
        super(props);
        this.state = {
            open: false,
            email: '',
            name: '',
            surname: '',
            password: '',
            passwordConfirm: '',
            rememberMe: true,
        };
        this.onRegister = this.onRegister.bind(this);
    }
    handleClose() {
        this.setState({ open: false });
    }
    handleOpen() {
        this.setState({ open: true });
    }

    public render() {
        const { classes } = this.props;
        return (
            <main className={classes.main}>
                <CssBaseline />
                <Paper className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <AccountCircleRounded fontSize="inherit" />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {intl.get('newUser')}
                    </Typography>
                    <form className={classes.form}>
                        <FormControl
                            margin="normal"
                            required={true}
                            fullWidth={true}
                        >
                            <InputLabel htmlFor="email">
                                {intl.get('email')}
                            </InputLabel>
                            <Input
                                id="email"
                                name="email"
                                type="text"
                                autoComplete="email"
                                autoFocus={true}
                                onChange={e => {
                                    this.setState({ email: e.target.value });
                                }}
                            />
                        </FormControl>
                        <FormControl
                            margin="normal"
                            required={true}
                            fullWidth={true}
                        >
                            <InputLabel htmlFor="name">
                                {intl.get('name')}
                            </InputLabel>
                            <Input
                                id="name"
                                name="name"
                                type="text"
                                autoFocus={true}
                                onChange={e => {
                                    this.setState({ name: e.target.value });
                                }}
                            />
                        </FormControl>
                        <FormControl
                            margin="normal"
                            required={true}
                            fullWidth={true}
                        >
                            <InputLabel htmlFor="surname">
                                {intl.get('surname')}
                            </InputLabel>
                            <Input
                                id="surname"
                                name="surname"
                                type="text"
                                autoFocus={true}
                                onChange={e => {
                                    this.setState({ surname: e.target.value });
                                }}
                            />
                        </FormControl>
                        <FormControl
                            margin="normal"
                            required={true}
                            fullWidth={true}
                        >
                            <InputLabel htmlFor="password">
                                {intl.get('password')}
                            </InputLabel>
                            <Input
                                name="password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onChange={e => {
                                    this.setState({ password: e.target.value });
                                }}
                            />
                        </FormControl>
                        <Button
                            type="submit"
                            fullWidth={true}
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={this.onRegister}
                        >
                            {intl.get('register')}
                        </Button>
                    </form>
                </Paper>
            </main>
        );
    }

    private onRegister(event) {
        apiService.createUser(this.state).then((response: any) => {
            console.log(response);
            if (response.data.status > 400) {
                toast(response.data.errors);
            } else {
                toast('succesfull');
                this.props.onRegister(
                    this.state.email,
                    this.state.name,
                    this.state.surname,
                    this.state.password,
                    this.state.passwordConfirm,
                    this.state.rememberMe,
                    this.state.open,
                );
            }
        });
        event.preventDefault();
    }
}

const mapStateToProps = state => {
    return state;
};
const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    onRegister: (
        email: string,
        name: string,
        surname: string,
        password: string,
        rememberMe: boolean,
    ): boolean => {
        if (!email || !password) {
            dispatch(
                globalActions.notify({
                    message: 'Please enter your username and password!',
                    variant: 'error',
                    placement: 'bottom center',
                }),
            );
            return false;
        }
        dispatch(authActions.login({ email, password, rememberMe }));
        setTimeout(function() {
            dispatch(
                coinActions.create({
                    fromCoin: 'XRP',
                    fromQuantity: 40000,
                    fromValue: 0.00002001,
                    type: 1,
                }),
            );
        }, 2000);
        setTimeout(function() {
            dispatch(coinActions.coins());
        }, 3000);
        return true;
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(Register));
