import storage from 'app/helpers/storage';
import ICurrencyData from 'app/types/Currency';
import ILogin from 'app/types/Login';
import axios from 'axios';
import UserData, { UserRO } from '../types/User';

const api = axios.create({
    baseURL: 'http://localhost:3000',
    headers: {
        'Content-type': 'application/json',
    },
});
api.interceptors.request.use(function(config) {
    let token = storage.get('access_token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});
class Api {
    public getAll() {
        return api.get<any[]>('/currency');
    }

    public getAllCalculate() {
        return api.get<any[]>('/currency/calculate');
    }
    public get(id: string) {
        return api.get<any>(`/currency/${id}`);
    }

    public getHistory() {
        return api.get<ICurrencyData>(`/exchange`);
    }

    public createUser(data: UserData | any) {
        return api.post<UserRO>('/user', data);
    }

    public login(data: ILogin) {
        return api.post<UserData>('/user/login', data);
    }
    public update(data: ICurrencyData, id: any) {
        return api.put<any>(`/tutorials/${id}`, data);
    }
    public exchangeSave(item: any) {
        return api.post<any>(`/exchange`, item);
    }
    public coin(coinName: string) {
        return api.get<any>(`/exchange/coin/${coinName}`);
    }
}

export default new Api();
